import {Asset, Entry, EntryCollection, EntrySkeletonType} from 'contentful'
import reduce from 'lodash/reduce'
import {
  ContentTypes,
  IMedia,
  IMediaWithContentFields,
  IResultSummaryPageFields,
  ITileItemFields,
  ITilesWithIconFields,
  TEntries,
  TEntry,
} from 'models/Contentful'

export const deserializeEntries: ({items}: EntryCollection<EntrySkeletonType, undefined, string>) => TEntries = ({
  items,
}) => {
  return items.map(deserializeEntry)
}

export const deserializeEntry = (item: Entry<any>): TEntry => {
  const {sys} = item
  const contentType = sys.contentType.sys.id as ContentTypes
  const res = {
    contentType,
    id: sys.id,
  }

  let deserializeMethod
  if (contentType === ContentTypes.mediaWithContent) {
    deserializeMethod = deserializeMediaWithContent
  } else if (contentType === ContentTypes.tileItem) {
    deserializeMethod = deserializeTileItem
  } else if (contentType === ContentTypes.tilesWithIcons) {
    deserializeMethod = deserializeTilesWithIcons
  } else if (contentType === ContentTypes.pageCopy) {
    deserializeMethod = deserializePageCopy
  } else if (contentType === ContentTypes.componentCopy) {
    deserializeMethod = deserializeComponentCopy
  } else if (contentType === ContentTypes.componentBlock) {
    deserializeMethod = deserializeComponentBlock
  }

  if (deserializeMethod) {
    Object.assign(res, deserializeMethod(item))
  }

  return res as TEntry
}

type TFieldsDeserialize<T> = (item: Entry<any>) => T

export const deserializeStatusPage: (
  entries: EntryCollection<EntrySkeletonType, undefined, string>,
) => TEntries = entries => {
  const {items} = entries || {}

  return (items?.[0]?.fields?.components as any)?.map((item: Entry<any>) => {
    const {sys} = item
    const contentType = sys.contentType.sys.id

    // We don't want to deserialize `componentBlock` for statusPage
    if (contentType === ContentTypes.componentBlock) {
      return item.fields
    }

    return deserializeEntry(item)
  })
}

export const deserializePageCopy: TFieldsDeserialize<IResultSummaryPageFields> = ({fields}) => {
  return {
    components: reduce(
      fields.componentBlocks as any,
      (res: any, {fields}: Entry<any>) => {
        const componentCopyOrBlock = deserializePageCopyComponents(fields)

        return {...res, [componentCopyOrBlock.type]: componentCopyOrBlock}
      },
      {},
    ),
  }
}

export const deserializePageCopyComponents = (fields: Record<string, any>) =>
  fields.type ? deserializeComponentCopy(fields) : deserializeComponentBlock({fields})

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
export const deserializeComponentCopy = fields => {
  const {textCopies, type} = fields
  const content = reduce(
    textCopies,
    (res: any, val: any) => {
      const isMedia = val.sys.contentType.sys.id === ContentTypes.media
      return {...res, [val.fields.key.trim()]: isMedia ? deserializeMedia(val.fields.media) : val.fields.copy}
    },
    {},
  )

  return {
    content,
    type,
  }
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fields' implicitly has an 'any' type.
export const deserializeComponentBlock = ({fields}) => {
  const {textCopies, componentName} = fields

  const content = reduce(
    textCopies,
    (res: any, val: any) => {
      const isMedia = val.sys.contentType.sys.id === ContentTypes.mediaContent

      return {
        ...res,
        [val.fields.key.trim()]: isMedia
          ? deserializeMedia(val.fields.media?.fields?.imageOrVideo?.fields?.asset)
          : val.fields.copy,
      }
    },
    {},
  )

  return {
    content,
    type: componentName,
  }
}

export const deserializeTileItem: TFieldsDeserialize<ITileItemFields> = ({fields}) => {
  const {title, content, icon} = fields

  return {content, icon, title} as any
}

export const deserializeMediaWithContent: TFieldsDeserialize<IMediaWithContentFields> = ({fields}) => {
  const {title, content, media, mediaPlacement, mediaBorder, video} = fields

  return {
    content,
    media: media ? deserializeMedia(media as any) : null,
    mediaBorder,
    mediaPlacement,
    title,
    video: (video as any)?.fields?.imageOrVideo.fields,
  } as any
}

export const deserializeTilesWithIcons: TFieldsDeserialize<ITilesWithIconFields> = ({fields}) => {
  const {header, subheader, tile} = fields

  return {
    header,
    subheader,
    tile: (tile as any).map(deserializeEntry),
  } as any
}

export const deserializeMedia: (item: Asset) => IMedia = ({fields}) => {
  if (!fields) return {} as IMedia

  const {title, description, file} = fields
  const {url, contentType, details} = file

  return {
    description,
    details,
    fileContentType: contentType,
    fileUrl: url,
    title,
  } as any
}
