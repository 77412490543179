// const for DatePicker
export const SERVER_DATE_FORMAT = 'yyyy-LL-dd'
export const OUTPUT_DATE_FORMAT = 'LL/dd/yyyy'
export const OUTPUT_DATE_MASK = '99/99/9999'
export const OUTPUT_DATE_INT = 'yyyy/LL/dd'

// const for date formats
// eslint-disable-next-line spellcheck/spell-checker
export const APPOINTMENT_DATE_FORMAT = 'ccc, LLL dd @h:mma'
export const GENETIC_COUNSELING_SERVICE_DATE_FORMAT = `LLLL d, yyyy 'at' h.mma ZZZZ`
export const MONTH_NUMBER_FORMAT = 'yyyy-M'
export const TIME_FORMAT = 'HH:mm'
export const US_TIME_FORMAT = 'hh:mm a'

// const for HOC
export const XS_MAX_WIDTH = 767
export const SM_MAX_WIDTH = 991
export const MD_MAX_WIDTH = 1199

// const for Share with family
export const SHARE_WITH_FAMILY_TEXT_TO_COPY = `Dear [family member name],
<br>
<br>
[As we talked about,] I had a genetic test and found out that I have a gene change (or variant) that can be passed on in families. So it’s possible that other people in our family also have this gene variant.
<br>
<br>
My genetic testing laboratory, Invitae, gave me this link to share so you can learn more about how to get family follow-up testing:
<br>
<br>
https://www.invitae.com/family-share
`

export enum ANALYTICS_QUEUE_EVENT_TYPES {
  videoWatched = 'Watch Video',
  declinedGCAppointment = 'Declined GC Appointment',
  scheduledGCAppointment = 'Scheduled GC Appointment',
  clickDiscoverHeartAppNextStep = 'Click learn more in the Discover Heart App targeted next step',
  clickCardiacInsightsDeviceNextStep = 'Click learn more in the Cardiac Insights Device targeted next step',
  clickTalkToGC = 'Click talk to a genetic counselor next step',
  clickCallUs = 'Click “call us”',
  clickScheduleAppointment = 'Click “Schedule Appointment” button',
  clickContinue = 'Select appointment time',
  cancelAppointment = 'Cancel appointment',
  rescheduleAppointment = 'Reschedule appointment',
  getCostEstimate = 'Get Cost Estimate',
  clickPredictedFetalSexModal = 'Click predicted fetal sex modal',
  clickIAmReadyInSexReveal = 'Click “I’m ready” in Sex Reveal',
  clickNotReadyInSexReveal = 'Click “Not ready” in Sex Reveal',
  watchSexRevealVideo = 'Watch sex reveal video',
  shareSexRevealVideo = 'Share sex reveal video',
  clickReadMoreInResultsSummary = 'Click “Read more” in Results Summary',
  downloadReport = 'Download Report',
  viewInteractiveResults = 'View Interactive Results',
  viewDocumentsTab = 'View Documents Tab in patient results',
  downloadPatientDocument = 'Download Patient Document',
  viewEstimate = 'Click View Estimate',
  viewReceipt = 'Click View Receipt',
}

export enum ANALYTICS_QUEUE_SITE_LOCATION_PROPERTY {
  testStatusPage = 'Test Status Page',
  resultsSummaryPage = 'Results Summary Page',
  documentsTab = 'Documents Tab',
}

export const classificationLabelsMap = [
  {key: 'PATHOGENIC', label: 'Pathogenic'},
  {key: 'PATHOGENIC_LOW_PENETRANCE', label: 'Pathogenic (low penetrance)'},
  {key: 'INCREASED_RISK_ALLELE', label: 'Increased Risk Allele'},
  {key: 'LIKELY_PATHOGENIC', label: 'Likely Pathogenic'},
  {key: 'VUS', label: 'Uncertain Significance'},
  {key: 'BENIGN', label: 'Benign'},
  {key: 'BENIGN', label: 'Likely Benign'},
]

export enum INVITAE_URLS {
  scheduler = 'https://invitae.as.me/schedule.php',
}

export const COUNTRY_US = 'US'
export const COUNTRY_CA = 'CA'
export const COUNTRY_PR = 'PR'

// gc-scheduler acuity codes for appointment type
export const ACUITY_CANADA = 'CA-XX'

export enum CURRENCY_SIGNS {
  dollarSign = '$',
  USDollarSign = 'US$',
}

/* eslint-disable spellcheck/spell-checker */
export const SEX_REVEAL_CONTENTFUL_SLUGS = {
  femaleFull: 'find-out-your-babys-predicted-sex',
  femaleShort: 'find-out-your-babys-predicted-sex-short',
  maleFull: 'learn-your-babys-predicted-sex',
  maleShort: 'learn-your-babys-predicted-sex-short',
}
/* eslint-enable spellcheck/spell-checker */

export const NIPS_RESULT_SUMMARY_VIDEOS = {
  negative: 'nips-negative-result',
  positive: 'nips-positive-result',
}

export const GENDER_MALE = 'male'
export const GENDER_FEMALE = 'female'
export const GENDER_INDETERMINATE = 'indeterminate'
export const GENDER_BOY = 'boy'
export const GENDER_GIRL = 'girl'

export const SCHEDULE_GENETIC_COUNSELING_BREADCRUMB_STEPS = ['Choose Appointment', 'Confirm']
export const SCHEDULE_GENETIC_COUNSELING_BREADCRUMB_STEPS_WITH_QUESTIONS = [
  'Choose Appointment',
  'Questions About You',
  'Confirm',
]

export const GC_SCHEDULER_TRS_DEFAULT_VALUE = 'None needed'
export const GC_SCHEDULER_TIMEZONE_DEFAULT_VALUE = 'America/Los_Angeles'

// Django auth messages
export const AUTH_CREDENTIALS_ERROR_MSG = 'Authentication credentials were not provided.'

// HTTP Status Codes
export const HTTP_403_FORBIDDEN = 403

export const IMGIX_WEB_BASE_URL = 'https://invitae.imgix.net/web/'

export const NA = 'N/A'
export const NO = 'NO'
export const YES = 'YES'

export const RELATIONSHIPS_TO_PROBAND = [
  'My results',
  'My partner/spouse’s results',
  'An egg and/or sperm donors’ results (authorization from your treating provider is required)',
  'My child’s result who is a minor',
  'My adult family member’s results',
  'Someone else’s results',
]

export const GC_ROUTES: {[key: string]: {path: string; title: string}} = {
  confirm: {
    path: 'schedule-genetic-counseling/:reqid/confirm',
    title: 'Confirm',
  },
  patient: {
    path: 'schedule-genetic-counseling/:reqid/patient',
    title: 'Questions About You',
  },
  root: {
    path: 'schedule-genetic-counseling/:reqid',
    title: 'Choose Appointment',
  },
}

export const GC_SERVICES_EMAIL = 'gcservices@invitae.com'
export const GC_SERVICES_TEL_STRING = 'tel:+1-415-854-5101'
export const GC_SERVICES_PHONE_STRING = '(415) 854-5101'
export const PAP_SERVICES_EMAIL_STRING = 'billing@invitae.com'
export const PAP_SERVICES_MAIL_STRING = 'mailto:billing@invitae.com'
export const BILLING_TEL_STRING = 'tel:+1-833-941-0828'
export const BILLING_PHONE_STRING = '(833) 941-0828'
export const BILLING_EMAIL_STRING = 'LcGeneticsBilling@labcorp.com'
export const BILLING_MAIL_STRING = 'mailto:lcgeneticsbilling@labcorp.com'

export const REPRODUCTIVE_RISK_SUMMARY_CONSENT_DISCLAIMER_TEXT =
  'Invitae Carrier Screening can now provide a Reproductive Risk Summary that shows the combined reproductive risk of you and your identified reproductive partner (only available for non-donor female-male pairings). If you or your healthcare provider requested this summary it will be delivered as a coversheet when you download your clinical report. If you need to make updates or wish to revoke your consent for this summary to be available please contact Invitae Client Services.'

// PatientInfoForm field labels

export const ADDITIONAL_ATTENDEES_LABEL =
  'Will there be anyone else joining the appointment? If so, include their name(s) and/or relationship to you.'
export const ADDITIONAL_COMMENTS_LABEL =
  'Is there anything else you would like your genetic counselor to know for your session (For example: preferred name, gender, identity, etc.)?'
export const ADDITIONAL_QUESTIONS_LABEL = 'What questions are you hoping to have answered during the session?'
export const EMAIL_LABEL = 'Email'
export const INTERPRETER_INFO_LABEL =
  'Do you need an interpreter for this appointment? If yes, include what language. Please indicate here if you will be providing Video Relay Service for deaf or hard of hearing patients.'
export const NAME_LABEL = 'Name'
export const RESULT_RECIPIENTS_LABEL = 'Whose results are these? Select all that apply.'
export const RQ_NUMBER_LIST_LABEL =
  'Please enter RQ or PAT number(s) found on the test report. If more than one, include all.'
export const SMS_REMINDER_CONSENT_LABEL = 'I would like to receive an SMS reminder before my appointment.'
export const RQ_NUMBER_LABEL = 'Please enter RQ number found on test report. If more than one, include all.'
export const RESULT_RECIPIENTS_LABEL_V2 = 'Whose results are these? Select all that apply.'
export const CHILD_RECEPIENT_INFO =
  'Please be sure to include the name(s) of the parent(s) that will be joining the session in the question above.'
export const ADULT_FAMILY_RECEPIENT_INFO =
  'If this family member is not going to be at the session, you must provide legal documentation that we are authorized to discuss their results with you. We will provide you with further information about what documentation is necessary once you have scheduled an appointment.'
export const RESULT_RECIPIENTS_OPTIONS = [
  {
    label: 'My results',
    value: 'My results',
  },
  {
    helpText: CHILD_RECEPIENT_INFO,
    label: 'My child’s result who is a minor',
    value: 'My child’s result who is a minor',
  },
  {
    helpText: ADULT_FAMILY_RECEPIENT_INFO,
    label: 'My adult family member’s results',
    value: 'My adult family member’s results',
  },
]

export const NON_MEDICARE_DISCLAIMER =
  'The actual amount you owe may be reduced if other procedures contribute towards your deductible prior to the billing of this test (after you receive your results)'
export const MEDICARE_COVERED_DISCLAIMER =
  'Based on the information provided by your healthcare provider and a review of applicable Medicare coverage criteria, this test would be considered a covered benefit'
export const MEDICARE_NON_COVERED_DISCLAIMER =
  'Based on the information provided by your healthcare provider and a review of applicable Medicare coverage criteria, this test would not be considered a covered benefit'
