import {TAPIUrl, TAPIUrlFunc} from 'models/API'
import {PORTAL_URL, STARGATE_URL} from 'services/invitaeUrl'

export const API_CONTACT: TAPIUrl = `${STARGATE_URL}/pe/users/current_user/`
export const API_REQUISITIONS: TAPIUrl = `${STARGATE_URL}/pe/requisitions/`
export const API_MARK_VIDEO_AS_WATCHED: TAPIUrl = `${STARGATE_URL}/pe/mark_video_as_watched/`
export const API_DOWNLOAD_REQUISITION_ATTACHMENT: TAPIUrlFunc = (reqid: number, attachmentId: number) =>
  `${PORTAL_URL}/reqrepo/pe/requisitions/${reqid}/attachments/${attachmentId}/download`
export const API_REGISTER_TEST: TAPIUrl = `${STARGATE_URL}/pe/users/register_test/`
export const API_USER_NOTIFICATION: TAPIUrl = `${STARGATE_URL}/common/user_notification/`
export const API_GPN: TAPIUrl = `${STARGATE_URL}/pe/gpn_search/`
export const API_COUNTRIES: TAPIUrl = `${STARGATE_URL}/common/countries/`
export const API_PROVINCES: TAPIUrl = `${STARGATE_URL}/common/country-state/`
export const API_PATIENT_PREFERENCES_DEFAULTS: TAPIUrl = `${STARGATE_URL}/common/patient_preferences/`
export const API_GET_DOCUMENTS: TAPIUrlFunc = reqId => `${STARGATE_URL}/pe/requisitions/${reqId}/documents/`
export const API_GET_RECEIPTS: TAPIUrlFunc = reqId => `${STARGATE_URL}/pe/requisitions/${reqId}/receipts/`
export const API_GET_VARIANTS: TAPIUrlFunc = reqId => `${STARGATE_URL}/pe/requisitions/${reqId}/variants/`
export const API_INITIALIZATION: TAPIUrl = `${STARGATE_URL}/pe/initialization/`
export const API_POST_CARRIER_PAYMENT: TAPIUrlFunc = reqId => `${STARGATE_URL}/pe/requisitions/${reqId}/payment/`
export const API_POST_DISMISS_GENETIC_COUNSELING: TAPIUrlFunc = reqId =>
  `${STARGATE_URL}/pe/requisitions/${reqId}/dismiss_genetic_counseling/`
export const API_POST_PATIENT_MULTIPLE_PAYMENT = `${STARGATE_URL}/pe/requisitions/multiple_payments/`
export const API_REFRESH_TOKEN_URL: TAPIUrl = `${STARGATE_URL}/auth/refresh_token`
export const API_FETCH_SHARE_LINKS: TAPIUrlFunc = reqId => `${STARGATE_URL}/pe/requisitions/${reqId}/share/`
export const API_DELETE_SHARE_LINK: TAPIUrlFunc = (reqId, link) =>
  `${STARGATE_URL}/pe/requisitions/${reqId}/share/${link}/`
export const API_PATIENTS: TAPIUrl = `${STARGATE_URL}/pe/patients/`
export const API_PATIENT_PREFERENCES: TAPIUrlFunc = patientId => `${API_PATIENTS}${patientId}/preferences/`
export const API_PATIENT_PREFERENCES_VIEW: TAPIUrlFunc = patientId => `${API_PATIENTS}${patientId}/view_preferences/`
export const API_GENETIC_COUNSELOR_STATES: TAPIUrl = `${STARGATE_URL}/pe/gc-scheduler/states/`
export const API_GENETIC_COUNSELORS: TAPIUrl = `${STARGATE_URL}/pe/gc-scheduler/genetic-counselors/`
export const API_GENETIC_COUNSELORS_AVAILABILITY_MONTH: TAPIUrlFunc = month =>
  `${STARGATE_URL}/pe/gc-scheduler/availability-month/${month}/`
export const API_GENETIC_COUNSELORS_AVAILABILITY_TIME: TAPIUrlFunc = month =>
  `${STARGATE_URL}/pe/gc-scheduler/availability-times/${month}/`
export const API_POST_GENETIC_COUNSELING_APPOINTMENT: TAPIUrl = `${STARGATE_URL}/pe/gc-scheduler/appointments/`
export const API_SCHEDULED_GENETIC_COUNSELING_APPOINTMENT: TAPIUrlFunc = reqid =>
  `${STARGATE_URL}/pe/gc-scheduler/appointments/${reqid}/`
export const API_INSURANCE_COMPANY_SEARCH = `${STARGATE_URL}/common/payor/search/`
export const API_MAKE_FTC_SELECTION: TAPIUrl = `${STARGATE_URL}/pe/make_flip_to_cash_selection/`
export const API_REQUEST_RESULTS: TAPIUrlFunc = reportId => `${STARGATE_URL}/pe/reports/${reportId}/request-results/`
export const API_MARK_REPORT_RELEASE_AS_VIEWED: TAPIUrlFunc = reportId =>
  `${STARGATE_URL}/pe/reports/${reportId}/mark-report-release/`

export const FEDEX_TRACKING_LINK = 'https://www.fedex.com/apps/fedextrack/?tracknumbers='
export const SIGN_IN_URL = `${PORTAL_URL}/en/profile/signin/`
export const SIGN_OUT_URL = `${PORTAL_URL}/en/profile/signout/`
export const PRIVACY_POLICY_URL = `${PORTAL_URL}/en/privacy/privacy-policy`
export const CONTACT_URL = `${PORTAL_URL}/en/contact/`
export const PATIENT_ASSISTANCE_PROGRAM_URL =
  'https://view.publitas.com/invitae/fm106_invitae_patient_assistance_program_application_form/page/1'
// eslint-disable-next-line spellcheck/spell-checker
export const MAKE_A_PAYMENT_URL = `${PORTAL_URL}/makeapayment`
export const INVITAE_XIFIN_URL = `https://www.dxlink.com/patientportal/signin.html?cn=invitae&login=statement#/login`
export const LABCORP_XIFIN_URL = 'https://www.dxlink.com/patientportal/signin.html?cn=lcgenetics#/login'

export const PAP_URL =
  'https://view.publitas.com/invitae/fm106_invitae_patient_assistance_program_application_form/page/1'
export const LABCORP_FAP_URL = 'https://view.publitas.com/invitae/955650_ff_projectionicfinancialassistance_form/page/1'

export const BILLING_PAGE = `${PORTAL_URL}/individual-faqs/billing`
