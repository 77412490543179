import {reducer as formReducer} from 'redux-form'

import contentfulReducer from './contentful'
import currentUserReducer from './currentUser'
import featureFlagsReducer from './featureFlags'
import geneticCounselingReducer from './geneticCounseling'
import notificationsReducer from './notifications'
import patientsReducer from './patients'
import requestResultsReducer from './requestResults'
import requisitionsReducer from './requisitions'
import shareLinksReducer from './shareLinks'
import {registrationReducer} from './testRegistration'
import userNotificationsReducer from './userNotifications'

export default {
  contentful: contentfulReducer,
  currentUser: currentUserReducer,
  featureFlags: featureFlagsReducer,
  form: formReducer,
  geneticCounseling: geneticCounselingReducer,
  notifications: notificationsReducer,
  patients: patientsReducer,
  registration: registrationReducer,
  requestResults: requestResultsReducer,
  requisitions: requisitionsReducer,
  shareLinks: shareLinksReducer,
  userNotifications: userNotificationsReducer,
}
